
import { defineComponent, inject, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { MarketConfig } from "@/types";
import { logoutUser } from "@/services/auth";

import Button from "@/components/Button.vue";

export default defineComponent({
  components: {
    Button
  },

  setup() {
    const claimLetterUrl = useRoute().params.claimLetterUrl as string;
    const instructions = (useRoute().params.nextSteps as string) || "";
    const successMessage = useRoute().params.successMessage as string;
    const rmaCode = useRoute().params.rmaCode as string;
    const { t } = useI18n();
    const { marketUrl, market } = inject("marketConfig") as MarketConfig;
    const emitter = inject("emitter") as any;
    const cookies = inject("cookies") as any;
    cookies.setCookie("donePageAccessible", "false");

    const openPopup = () => {
      emitter.emit("showAlert", { type: "donePopup", code: rmaCode });
    };

    onMounted(() => {
      window.scrollTo(0, 0);
    });
    return {
      marketUrl,
      market,
      t,
      claimLetterUrl,
      instructions,
      successMessage,
      rmaCode,
      openInNewTab: (url: string): void => {
        window.open(url);
      },
      openPopup,
      logoutUser
    };
  }
});
